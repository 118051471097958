import { Link } from "gatsby";
import React from "react";

const MenuLink = ({ to, label }) => {
    return (
        <li>
            <Link to={to} activeClassName="active" partiallyActive={true}>
                {label}
            </Link>
        </li>
    );
};

const Menu = ({ noActiveMenu }) => {
    const options = [{ to: "/projects", label: "Projects" }];

    return (
        <nav className={`site-menu ${noActiveMenu ? "" : "site-menu-active"}`}>
            <ul>
                {options.map((o, idx) => (
                    <MenuLink key={idx} to={o.to} label={o.label} />
                ))}
            </ul>
        </nav>
    );
};

export default Menu;
