import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import logo from "../images/logo-fg-v.svg";
import Menu from "./menu";

const Header = ({ siteTitle, noActiveMenu }) => (
    <header className="site-header">
        <div className="site-header-holder">
            <Link
                to="/"
                style={{
                    textDecoration: `none`,
                }}
            >
                <img className="header-logo" src={logo} alt={siteTitle} />
            </Link>
            <Menu noActiveMenu={noActiveMenu} />
        </div>
    </header>
);

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
