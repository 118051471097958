/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import Header from "./header";
import "./styles/layout.css";
import "./styles/common.css";

const Layout = ({ children, noActiveMenu }) => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `}
        render={(data) => (
            <>
                <Header
                    siteTitle={data.site.siteMetadata.title}
                    noActiveMenu={noActiveMenu}
                />
                <div
                    style={{
                        margin: `0 auto`,
                        position: `relative`,
                    }}
                >
                    <main>{children}</main>
                    <footer></footer>
                </div>
            </>
        )}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
